const bail = (error) => {
    // Swallow all errors.
    // TODO: Turn off Heap tracking for a while (short-circuit?)
    // KLUDGE: These properties don't show up if you `console.warn` the error object directly.
    const errorData = {
        name: error.name,
        message: error.message,
        stack: error.stack,
    };
    console.warn('The Heap library has crashed with an exception.', errorData);
};
const bailOnError = (f) => (...args) => {
    try {
        return f(...args);
    }
    catch (e) {
        if (e instanceof Error) {
            bail(e);
        }
        else {
            bail(new Error(String(e)));
        }
    }
};
export { bail, bailOnError };
