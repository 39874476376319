var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { getBaseComponentProps } from './common';
import { bailOnError } from '../util/bailer';
import { getComponentDisplayName } from '../util/hocUtil';
export const withHeapPressableAutocapture = track => PressableComponent => {
    class HeapPressableAutocapture extends React.Component {
        trackEvent(isLongPress) {
            const autotrackProps = getBaseComponentProps(this);
            if (!autotrackProps) {
                // We're not capturing this interaction.
                return;
            }
            autotrackProps.is_long_press = isLongPress;
            track('touch', autotrackProps);
        }
        render() {
            const _a = this.props, { heapForwardedRef, onPress, onLongPress } = _a, rest = __rest(_a, ["heapForwardedRef", "onPress", "onLongPress"]);
            return (React.createElement(PressableComponent, Object.assign({ ref: heapForwardedRef, onPress: e => {
                    bailOnError(() => this.trackEvent(false))();
                    onPress && onPress(e);
                }, onLongPress: e => {
                    bailOnError(() => this.trackEvent(true))();
                    onLongPress && onLongPress(e);
                } }, rest), this.props.children));
        }
    }
    HeapPressableAutocapture.displayName = `withHeapPressableAutocapture(${getComponentDisplayName(PressableComponent)})`;
    const forwardRefHoc = React.forwardRef((props, ref) => {
        return React.createElement(HeapPressableAutocapture, Object.assign({}, props, { heapForwardedRef: ref }));
    });
    hoistNonReactStatic(forwardRefHoc, PressableComponent);
    return forwardRefHoc;
};
